import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/syeda/corvette/src/components/post-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How do you drive a Corvette over speed bumps?`}</h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1103px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/b906ffad42ae16f89d28bbb9fe1fa0b2/f1452/supercar-chevy-corvette-zo6.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.33333333333333%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAIAAf/aAAwDAQACEAMQAAABsiWzLROB/wD/xAAdEAACAQQDAAAAAAAAAAAAAAACAwEABBESEyMz/9oACAEBAAEFAuGIowXlepCsy1uZ6V+f/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAHRAAAgIBBQAAAAAAAAAAAAAAAAEREiEiMTJhkf/aAAgBAQAGPwLEdji+ndzgmpyfoxH/xAAcEAADAAEFAAAAAAAAAAAAAAAAARExIUFRkcH/2gAIAQEAAT8haGm8Uy9aEqOgb3oytaGZnsPI8H//2gAMAwEAAgADAAAAEPzP/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAEhMf/aAAgBAwEBPxB0WH//xAAXEQADAQAAAAAAAAAAAAAAAAAAATEh/9oACAECAQE/EFkHT//EABwQAQACAgMBAAAAAAAAAAAAAAEAESExQVFhcf/aAAgBAQABPxASM3Gx8wwgm2nWOFmW+oWdS0KTYc7jopRtMaSx2fSMNJtif//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Supercar Chevy Corvette Z06",
            "title": "Supercar Chevy Corvette Z06",
            "src": "/static/b906ffad42ae16f89d28bbb9fe1fa0b2/f1452/supercar-chevy-corvette-zo6.jpg",
            "srcSet": ["/static/b906ffad42ae16f89d28bbb9fe1fa0b2/f93b5/supercar-chevy-corvette-zo6.jpg 300w", "/static/b906ffad42ae16f89d28bbb9fe1fa0b2/b4294/supercar-chevy-corvette-zo6.jpg 600w", "/static/b906ffad42ae16f89d28bbb9fe1fa0b2/f1452/supercar-chevy-corvette-zo6.jpg 1103w"],
            "sizes": "(max-width: 1103px) 100vw, 1103px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`If you want to go over a speed bump, you should accelerate over it. This will raise the front end of the Corvette two inches in less than three seconds. But be careful not to accelerate too much, or you'll have problems with steering and vehicle control. The best way to go over a speed bump is to let off the brake just before the bump. If you're going over a bump with a high or narrow rise, then the vehicle will automatically lower itself.`}</p>
    <p>{`The center section of the car's tailpipe will lift the car up when you hit something, but it will flex when it hits something. It's also very easy to do this with a buddy. You'll need to make sure your tailpipe is straight and that your muffler is on a straight line. Once you're past the bump, you can move on to the next bump.`}</p>
    <p>{`A few different things need to happen before you can drive a Corvette over speed bumps. First, you have to take your foot off the gas pedal and make sure your car is stationary. You need to avoid going over the bump if you want to protect the engine. Secondly, you need to keep the car at a reasonable speed. You don't want to hit the bump at the wrong time.`}</p>
    <p>{`In order to get over a speed bump, you need to raise the nose of the car. This will raise it 1.5 inches, which is sufficient to climb a ramp. Using the suspension system in your Corvette will allow you to go over a speed bump. Then, you can accelerate moderately and avoid braking when you cross over the bump. However, the biggest drawback of angle driving is that it is more awkward to do in tight places.`}</p>
    <p>{`When driving over a speed bump, the first thing you need to remember is that the car's suspension will bottom out. If the front wheels are above the bump, anything behind them will smack the unibody rails. This will damage the car's fuel tank. If you're not careful, you could end up damaging your suspension. This will cause a loss of performance.`}</p>
    <p>{`If you don't want to risk damaging your vehicle, you should be aware of the potential for damage. In addition to causing minor damage, it's also dangerous for your Corvette. You should avoid speed bumps with caution. They can cause your Corvette to crash. And you shouldn't allow them to crash because they will damage your car's exhaust. You must drive carefully.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      